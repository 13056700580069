<template>
  <div class="specta-container">
    <div class="loader">
      <Loading />
      <p>Please hold on while we confirm payment.</p>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data: () => ({
    loading: true,
  }),
  beforeRouteEnter(to, from, next) {
    const { ref } = to.query;
    if (!ref) {
      return next("/checkout");
    }
    next();
  },
  mounted() {
    // console.log(this.$route);
    this.verifySpecta();
  },
  methods: {
    verifySpecta() {
      // console.log("Na the one ");

      let ref = decodeURI(this.$route.query.ref);
      let data = {
        verificationToken: ref,
      };
      data = this.$helpers.encrypt(JSON.stringify(data));

      data = {
        data,
      };
      let payload = {
        data,
        path: `lease/verifyPurchase/${this.$route.params.id}`,
      };
      this.$store
        .dispatch("postRequest", payload)
        .then(({ data }) => {
          let decr = JSON.parse(this.$helpers.decrypt(data.data));
          // console.log(decr);
          data = decr;
          const {
            result: { message },
          } = data.data;
          switch (message) {
            case "Completed":
              this.$store.commit("updateCart", []);
              this.$toast.success(
                "Success",
                "Your order is being processed.",
                this.$toastPosition
              );
              this.$router.push("/my-account?specta=successful");
              setTimeout(() => {
                window.open(
                  "https://sterlingbank.eu.qualtrics.com/jfe/form/SV_dik9Ou2jB0zO3Ea",
                  "_blank"
                );
              }, 2000);
              break;
            case "Failed":
              this.showModal({
                description: "Your loan request was not successful.",
                display: true,
                type: "error",
              });
              this.$toast.error(
                "Error",
                "Your loan request was not successful.",
                this.$toastPosition
              );
              this.$router.push("/checkout");
              break;
            default:
              alert("an error occured");
              break;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            "Error",
            "We're having issues verifying you loan payment, please contact support.",
            this.$toastPosition
          );
          this.$router.push("/checkout");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.specta-container {
  min-height: 35rem;
  display: grid;
  place-items: center;

  .loader {
    text-align: center;
  }
}
</style>
